<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()"
							:disabled="isSaving"
							type="button"
							class="btn btn-info mt-3">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right"
								to="/admin/Admin/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="account" class="col-sm-2 col-form-label">帳號</label>
							<div class="col-sm-10">
								<input v-model="model.account"
									type="text"
									class="form-control"
									id="account"
									name="account"
								required readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="username" class="col-sm-2 col-form-label">員工姓名</label>
							<div class="col-sm-10">
								<input v-model="model.username"
									type="text"
									class="form-control"
									id="username"
									name="username"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="password" class="col-sm-2 col-form-label">密碼</label>
							<div class="col-sm-10">
								<input v-model="model.password"
									type="text"
									class="form-control"
									id="password"
									name="password"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="mail" class="col-sm-2 col-form-label">電子信箱</label>
							<div class="col-sm-10">
								<input v-model="model.mail"
									type="text"
									class="form-control"
									id="mail"
									name="mail"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="role" class="col-sm-2 col-form-label">角色群組</label>
							<div class="col-sm-10">
								<input v-model="model.role"
									type="text"
									class="form-control"
									id="role"
									name="role"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="is_super_admin" class="col-sm-2 col-form-label">超級管理員</label>
							<div class="col-sm-10">
								<input v-model="model.is_super_admin"
									type="text"
									class="form-control"
									id="is_super_admin"
									name="is_super_admin"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="created_at" class="col-sm-2 col-form-label">建立日期</label>
							<div class="col-sm-10">
								<input v-model="model.created_at"
									type="date"
									class="form-control"
									id="created_at"
									name="created_at"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="updated_at" class="col-sm-2 col-form-label">更新日期</label>
							<div class="col-sm-10">
								<input v-model="model.updated_at"
									type="date"
									class="form-control"
									id="updated_at"
									name="updated_at"
								/>
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
	import LayoutDiv from '@/components/LayoutDiv.vue'
	import { addAdmin } from '@/api/Admin.js'

	export default {
		name: 'ProjectCreate',
		components: {
			LayoutDiv
		},
		data() {
			return {
				model: {
					account: '',
					username: '',
					password: '',
					mail: '',
					role: '',
					is_super_admin: '',
					created_at: '',
					updated_at: '',

				},
				isSaving: false,
			};
		},
		methods: {
			handleSave() {
				this.isSaving = true

				addAdmin(this.model).then(rep => {

					this.isSaving = false;
					this.reset();
					return rep;
				})
				.catch(error => {
					this.isSaving = false
					return error
				});
			},
			reset() {
					this.model.account = "";
					this.model.username = "";
					this.model.password = "";
					this.model.mail = "";
					this.model.role = "";
					this.model.is_super_admin = "";
					this.model.created_at = "";
					this.model.updated_at = "";

			}
		}
	};
</script>
